import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import blogStyle from "../styles/blog.module.scss";

export const query = graphql`
    query BlogQuery($slug: String) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            frontmatter {
                date
                title
                twitter_reply_id
            }
            excerpt
            html
            fields {
                slug
            }
        }
    }
`;

const Blog = (props) => {
    const { data } = props;

    return (
        <Layout>
            <div className={blogStyle.root}>
                <div className={blogStyle.spacer}></div>
                <p>{data.markdownRemark.frontmatter.date}</p>
                <h2 className={blogStyle.title}>
                    {data.markdownRemark.frontmatter.title}
                </h2>

                <div
                    dangerouslySetInnerHTML={{
                        __html: data.markdownRemark.html,
                    }}
                    className={blogStyle.post}
                ></div>

                {data.markdownRemark.frontmatter.twitter_reply_id !== null && (
                    <div className={blogStyle.reply}>
                        <a
                            className={blogStyle.btn}
                            href={`https://twitter.com/intent/tweet?in_reply_to=${data.markdownRemark.frontmatter.twitter_reply_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h4>ツイッターでコメントする</h4>
                        </a>
                    </div>
                )}
            </div>
            <SEO
                lang="ja"
                title={data.markdownRemark.frontmatter.title}
                description={data.markdownRemark.excerpt}
                slug={data.markdownRemark.fields.slug}
            />
        </Layout>
    );
};

export default Blog;
